var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: _vm.$t("lbl_scale"), closable: false, visible: _vm.value }
    },
    [
      _c(
        "a-alert",
        {
          attrs: {
            type: _vm.hasError ? "error" : "success",
            message: _vm.connectionStatus,
            "show-icon": ""
          }
        },
        [
          _c("span", { attrs: { slot: "description" }, slot: "description" }, [
            _vm._v(_vm._s(_vm.lastSync))
          ])
        ]
      ),
      _c("a-divider"),
      _c("div", { staticClass: "text-center" }, [
        _c("h1", { staticClass: "text-uppercase" }, [
          _vm._v(
            " " +
              _vm._s(_vm.form.rawWeight || 0) +
              " " +
              _vm._s(_vm.form.unit) +
              " "
          )
        ])
      ]),
      _c(
        "a-form-model",
        {
          ref: "theForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-align": "left",
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_choose_scale"), prop: "scaleId" } },
            [
              _c(
                "a-select",
                {
                  attrs: { loading: _vm.loading.list, "allow-clear": "" },
                  model: {
                    value: _vm.form.scaleId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "scaleId", $$v)
                    },
                    expression: "form.scaleId"
                  }
                },
                _vm._l(_vm.listScale, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id },
                    [
                      _c("a-tooltip", [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(" " + _vm._s(item.name || "-") + " ")]
                        ),
                        _vm._v(" " + _vm._s(item.name || "-") + " ")
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_weight"), prop: "weight" } },
            [
              _c("a-input-number", {
                staticStyle: { width: "100%" },
                attrs: {
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  placeholder: _vm.$t("lbl_type_here"),
                  min: 0
                },
                model: {
                  value: _vm.form.weight,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "weight", _vm._n($$v))
                  },
                  expression: "form.weight"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "a-button",
            {
              staticClass: "mb-2",
              attrs: {
                block: "",
                type: "primary",
                loading: _vm.loading.sync,
                icon: "sync"
              },
              on: { click: _vm.validateForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_synchronize")) + " ")]
          ),
          _c(
            "a-button",
            { attrs: { block: "" }, on: { click: _vm.handleReset } },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleClose } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
              ]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "save" },
                  on: { click: _vm.handleSave }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }