import { Api } from "@/models/class/api.class";
import { ScaleError, ScaleGet, ScalePost, ScalePostPrintResponse, ScalePostResponse } from "@/models/interface-v2/scale.interface";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class ScaleServices extends HttpClient {
  constructor() {
    super();
  }

  getList(): Promise<ScaleGet> {
    return this.get<ScaleGet>(`${Api.MASTER_PATH_SCALE_API}scale/browse`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      }
    })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  postScale(payload: ScalePost): Promise<ScalePostResponse | ScalePostPrintResponse> {
    return this.post<ScalePostResponse | ScalePostPrintResponse, ScalePost>(`${Api.MASTER_PATH_SCALE_API}scale`, payload, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      }
    })
    .then(this.success)
    .catch((error: AxiosError<ScaleError>) => {
      throw this.error(error);
    });
  }

}

export const scaleService = new ScaleServices();
