



































































































import Vue from "vue";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_QTY } from "@/models/constant/global.constant";
import { scaleService } from "@service/scale.service";
import { ScaleEntity, ScalePost } from "@/models/interface-v2/scale.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FormModel } from "ant-design-vue";
import { DEFAULT_DATE_FORMAT_TIME } from "@/models/constant/date.constant";
import moment from "moment";
export default Vue.extend({
  name: "CScale",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      DECIMAL_PLACES_QTY,
      form: {
        scaleId: undefined as string | undefined,
        weight: 0,
        rawWeight: 0,
        unit: "KG",
      },
      rules: {
        scaleId: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        weight: [{ required: false, message: () => this.$t("lbl_validation_required_error") }],
      },
      listScale: [] as ScaleEntity[],
      loading: {
        list: false,
        sync: false,
      },
      lastSync: "",
      hasError: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
    };
  },
  computed: {
    connectionStatus(): string {
      return this.hasError ? this.$t("lbl_offline").toString() : this.$t("lbl_online").toString();
    }
  },
  created(): void {
    this.getListScale();
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    moment,
    async getListScale(): Promise<void> {
      try {
        this.loading.list = true;
        const { data } = await scaleService.getList();
        this.listScale = [...data];
        this.hasError = false;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
        this.hasError = true;
      } finally {
        this.loading.list = false;
        this.lastSync = `${this.$t("lbl_last_sync")} ${this.moment().format(DEFAULT_DATE_FORMAT_TIME)}`;
      }
    },
    handleSave(): void {
      this.$emit("on-save", { value: this.form.weight });
      this.$emit("input", false);
    },
    handleClose(): void {
      this.$emit("input", false);
    },
    handleReset(): void {
      const $form = this.$refs.theForm as FormModel;
      $form.resetFields();
      this.form.rawWeight = 0;
      this.form.unit = "KG";
    },
    validateForm(): void {
      const $form = this.$refs.theForm as FormModel;
      $form.validate((valid: boolean) => {
        if (valid) {
          this.getWeight();
        }
      });
    },
    async getWeight(): Promise<void> {
      try {
        this.loading.sync = true;
        const payload: ScalePost = {
          id: this.form.scaleId || "",
          action: "print"
        };
        const { data } = await scaleService.postScale(payload);
        if ("netto" in data) {
          this.form.rawWeight = data.netto.value;
          this.form.weight = data.netto.value;
          this.form.unit = data.netto.unit;
        }
        this.hasError = false;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
        this.hasError = true;
      } finally {
        this.loading.sync = false;
        this.lastSync = `${this.$t("lbl_last_sync")} ${this.moment().format(DEFAULT_DATE_FORMAT_TIME)}`;
      }
    }
  }
});
